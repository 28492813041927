import { Header, Footer } from 'components';

export function EULA() {
  return (
    <>
      <Header />
      <div style={{
        marginTop: '100px', textAlign: 'left', padding: '20px',
      }}
      >
        <h1>Contrato de Licença de Usuário Final (EULA) - Pandlr.com</h1>

        <h2>1. Aceitação da Licença</h2>
        <p>
          Este Contrato de Licença de Usuário Final é um acordo legal entre você Usuário e Pandlr.com. Ao acessar ou
          utilizar o site Pandlr.com, você concorda em cumprir os termos e condições descritos neste EULA. Caso não concorde,
          você não deve utilizar o site.
        </p>

        <h2>2. Concessão de Licença</h2>
        <p>
          Pandlr.com concede ao Usuário uma licença limitada, não exclusiva, intransferível e revogável para acessar e utilizar o
          conteúdo e serviços oferecidos no site exclusivamente para fins pessoais e não comerciais.
        </p>

        <h2>3. Restrições de Uso</h2>
        <p>
          O Usuário concorda em não:
        </p>
        <ul>
          <li>Modificar, distribuir, licenciar ou vender qualquer parte do conteúdo do site.</li>
          <li>Usar o site para qualquer propósito ilegal ou não autorizado.</li>
          <li>Tentar descompilar, realizar engenharia reversa ou tentar extrair o código-fonte de qualquer parte do site.</li>
          <li>Interferir ou comprometer a integridade ou o desempenho do site ou de seus serviços relacionados.</li>
        </ul>

        <h2>4. Propriedade Intelectual</h2>
        <p>
          Todos os direitos sobre o conteúdo, incluindo, sem limitação, textos, imagens, gráficos, e software, são de propriedade
          exclusiva do Pandlr.com ou de seus licenciadores. Esta licença não concede ao Usuário qualquer direito sobre o código-fonte
          ou conteúdo além do uso limitado descrito neste EULA.
        </p>

        <h2>5. Atualizações</h2>
        <p>
          Pandlr.com pode fornecer atualizações, patches ou novas versões do software ou conteúdo do site. Qualquer atualização
          fornecida ao Usuário estará sujeita aos termos deste EULA, a menos que estabelecido de outra forma.
        </p>

        <h2>6. Limitação de Garantias</h2>
        <p>
          O site é fornecido no estado em que se encontra e sem garantias de qualquer tipo, expressas ou implícitas. Pandlr.com
          não garante que o site funcionará sem interrupções ou erros, nem que quaisquer erros serão corrigidos.
        </p>

        <h2>7. Limitação de Responsabilidade</h2>
        <p>
          Em nenhuma circunstância o Pandlr.com será responsável por quaisquer danos indiretos, incidentais, consequenciais, especiais
          ou punitivos, decorrentes do uso ou da incapacidade de uso do site ou dos seus conteúdos.
        </p>

        <h2>8. Rescisão</h2>
        <p>
          Pandlr.com reserva-se o direito de rescindir este EULA e revogar sua licença a qualquer momento, com ou sem aviso prévio, se
          você violar qualquer um dos termos aqui estabelecidos. Após a rescisão, você deve interromper imediatamente o uso do site.
        </p>

        <h2>9. Lei Aplicável</h2>
        <p>
          Este EULA será regido e interpretado de acordo com as leis do Brasil, e quaisquer disputas serão resolvidas nos tribunais
          competentes no Brasil.
        </p>

        <h2>10. Contato</h2>
        <p>
          Se você tiver dúvidas sobre este EULA, entre em contato conosco pelo e-mail:
          contato@pandlr.com
          .
        </p>
      </div>
      <Footer showTerms />
    </>
  );
}
