import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export function Footer({ showTerms = false }) {
  return (
    <Box textAlign="center" marginTop={2} marginBottom={2}>
      Copyright © Pandlr 2015 - 2024.
      <Box>Versão: 3.0.48.</Box>
      {showTerms && (
        <Box>
          <Link to="/eula" style={{ color: 'inherit', textDecoration: 'none' }}>EULA</Link>
          {' '}
          -
          {' '}
          <Link to="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>Política de Privacidade</Link>
          {' '}
          -
          {' '}
          <Link to="/tos" style={{ color: 'inherit', textDecoration: 'none' }}>Termos de Serviço</Link>
        </Box>
      )}
    </Box>
  );
}
