import { Header, Footer } from 'components';

export function Privacy() {
  return (
    <>
      <Header />
      <div style={{
        marginTop: '100px', textAlign: 'left', padding: '20px',
      }}
      >
        <h1>Política de Privacidade - Pandlr.com</h1>

        <h2>1. Introdução</h2>
        <p>
          A sua privacidade é importante para nós. Esta política de privacidade descreve como coletamos, usamos e
          protegemos as suas informações pessoais ao utilizar o Pandlr.com.
        </p>

        <h2>2. Informações Coletadas</h2>
        <p>
          Podemos coletar informações pessoais identificáveis, como nome, e-mail e outros dados fornecidos
          voluntariamente por você ao utilizar o site, como em cadastros ou comentários.
        </p>

        <h2>3. Uso das Informações</h2>
        <p>
          As informações coletadas são utilizadas para:
        </p>
        <ul>
          <li>Personalizar sua experiência no site.</li>
          <li>Melhorar nosso serviço e conteúdo.</li>
          <li>Entrar em contato com você para fornecer informações relevantes.</li>
          <li>Resolução de problemas e suporte ao usuário.</li>
        </ul>

        <h2>4. Compartilhamento de Informações</h2>
        <p>
          Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:
        </p>
        <ul>
          <li>Cumprir exigências legais.</li>
          <li>Proteger nossos direitos e segurança.</li>
          <li>Oferecer serviços solicitados por você, como integração com plataformas de pagamento ou parceiros de publicidade.</li>
        </ul>

        <h2>5. Cookies</h2>
        <p>
          Pandlr.com utiliza cookies para melhorar sua experiência de navegação, armazenando informações sobre suas
          preferências. Você pode desativar os cookies nas configurações do seu navegador, porém algumas funcionalidades
          do site podem ser afetadas.
        </p>

        <h2>6. Segurança das Informações</h2>
        <p>
          Tomamos medidas razoáveis para proteger as informações pessoais que coletamos. No entanto, nenhum sistema é
          completamente seguro, e não podemos garantir a segurança absoluta de suas informações.
        </p>

        <h2>7. Seus Direitos</h2>
        <p>
          Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para exercer
          esses direitos, entre em contato pelo e-mail:
          contato@pandlr.com
          .
        </p>

        <h2>8. Alterações nesta Política</h2>
        <p>
          Esta política de privacidade pode ser atualizada periodicamente. As alterações serão publicadas nesta página,
          e é sua responsabilidade revisar a política regularmente.
        </p>

        <h2>9. Contato</h2>
        <p>
          Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco pelo e-mail:
          contato@pandlr.com
          .
        </p>
      </div>
      <Footer showTerms />
    </>
  );
}
