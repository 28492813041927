import { Header, Footer } from 'components';

export function TOS() {
  return (
    <>
      <Header />
      <div style={{
        marginTop: '100px', textAlign: 'left', padding: '20px',
      }}
      >
        <h1>Termos de Uso - Pandlr.com</h1>

        <h2>1. Aceitação dos Termos</h2>
        <p>
          Ao acessar e utilizar o site Pandlr.com, você concorda com os termos e condições estabelecidos abaixo. Caso
          não concorde com qualquer um dos termos, solicitamos que não utilize o site.
        </p>

        <h2>2. Alterações dos Termos</h2>
        <p>
          Pandlr.com reserva-se o direito de modificar ou alterar estes Termos de Uso a qualquer momento. É sua
          responsabilidade revisar regularmente esta página para garantir que você está ciente de qualquer alteração.
        </p>

        <h2>3. Uso do Site</h2>
        <p>
          Pandlr.com é uma plataforma online de compartilhamento de conteúdo e interação entre usuários. Ao utilizar o
          site, você concorda em:
        </p>
        <ul>
          <li>Não publicar ou compartilhar conteúdo que seja ilegal, ofensivo, difamatório, ou que viole os direitos de terceiros.</li>
          <li>Não utilizar o site para praticar atividades que violem leis ou regulamentos aplicáveis.</li>
          <li>Respeitar a política de privacidade e os direitos de outros usuários da plataforma.</li>
        </ul>

        <h2>4. Conteúdo do Usuário</h2>
        <p>
          Você é o único responsável pelo conteúdo que publica no site Pandlr.com. Pandlr.com não assume qualquer responsabilidade
          por danos decorrentes de conteúdo postado pelos usuários.
        </p>

        <h2>5. Direitos Autorais e Propriedade Intelectual</h2>
        <p>
          Todo o conteúdo disponível no Pandlr.com, incluindo, mas não se limitando a, textos, imagens, vídeos e gráficos, é de
          propriedade da Pandlr.com ou de seus licenciadores. Você não pode copiar, reproduzir, modificar, ou distribuir qualquer
          conteúdo sem a autorização prévia e expressa de seus proprietários.
        </p>

        <h2>6. Denúncias e Reclamações</h2>
        <p>
          Caso você identifique qualquer conteúdo que viole estes Termos de Uso, poderá reportar através do e-mail:
          denuncias@pandlr.com
          .
        </p>

        <h2>7. Reclamações de Copyright</h2>
        <p>
          Se você acredita que algum conteúdo disponível no Pandlr.com infringe seus direitos autorais, envie uma notificação
          detalhada para
          {' '}
          copyright@pandlr.com
          {' '}
          contendo as seguintes informações:
        </p>
        <ul>
          <li>Identificação da obra protegida por direitos autorais que supostamente foi violada.</li>
          <li>Identificação do material que infringe os direitos autorais e que você deseja que seja removido ou tenha seu acesso desabilitado.</li>
          <li>Seus dados de contato, incluindo endereço, telefone e e-mail.</li>
          <li>Declaração de boa-fé de que o uso do material não está autorizado pelo proprietário dos direitos autorais.</li>
        </ul>

        <h2>8. Publicidade</h2>
        <p>
          Para informações sobre oportunidades de publicidade no Pandlr.com, entre em contato pelo e-mail
          publicidade@pandlr.com
          .
        </p>

        <h2>9. Limitação de Responsabilidade</h2>
        <p>
          Pandlr.com não se responsabiliza por quaisquer danos diretos, indiretos, incidentais ou consequenciais resultantes do uso ou da
          incapacidade de uso do site. O uso do site é de sua inteira responsabilidade.
        </p>

        <h2>10. Lei Aplicável</h2>
        <p>
          Estes Termos de Uso são regidos pelas leis brasileiras. Quaisquer disputas decorrentes destes Termos ou do uso do site serão
          resolvidas nos tribunais competentes no Brasil.
        </p>

        <h2>11. Contato</h2>
        <p>
          Se você tiver dúvidas sobre os Termos de Uso, entre em contato conosco pelo e-mail:
          contato@pandlr.com
          .
        </p>
      </div>
      <Footer showTerms />
    </>
  );
}
